import imgWhatsapp from '../assests/images/btn_whatsapp.png';
import { getEnv, WA_CC_NUMBER } from '../api/env';

export const loadWebchat = () => {
  const js = document.createElement('div');
  js.id = 'webchat';
  js.style.position = 'fixed';
  js.style.bottom = 0;
  js.style.right = 0;
  js.style.float = 'right';
  js.style.marginBottom = '10px';
  js.style.zIndex = 100;
  js.addEventListener('click', () => {
    const ccPhoneNumberWhatsapp = getEnv(WA_CC_NUMBER);
    window.open(`https://api.whatsapp.com/send?phone=${ccPhoneNumberWhatsapp}&text=${encodeURIComponent('Hola')}`);
  });
  const img = document.createElement('img');
  img.style.width = '60px';
  img.src = imgWhatsapp;
  js.appendChild(img);
  document.body.appendChild(js);
};

export const deleteWebChat = () => {
  const webchatDiv = document.getElementById('webchat');
  if (webchatDiv) {
    webchatDiv.remove();
  }
};
